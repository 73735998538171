import React, { Component } from 'react';
import { Row, Col, ScreenClassRender } from 'react-grid-system';
import { MaoComDinheiro, Jantar } from '../../../components/Icons';
import ActionButton from '../../../components/ActionButton';
import { firebaseDatabase } from '../../../utils';
import "./index.css";

const COLLECTION_CONFIGS = "config";

class Arrecadado extends Component {

    constructor() {
        super()

        this.state = {};
    }

    componentDidMount() {
        this.buscaConfigs();
    }

    async buscaConfigs() {
        const response = firebaseDatabase.collection(COLLECTION_CONFIGS);
        const data = await response.get();
        let configs = data.docs[0].data();

        this.setState({
            valorArrecadado: configs.valorArrecadado,
            alimentoArrecadado: configs.alimentoArrecadado
        })
    }

    render() {
        return (
            <ScreenClassRender render={screenClass => (
                <Row style={{ margin: 0 }}>
                    <Col className="backgroundArrecadado" xs={12}>
                        <Row justify="center">
                            {/* Arrecadado */}
                            <Col xs={12} sm={12} md={12} lg={3} xl={3} style={{ padding: 0, textAlign: "left", textAlign: "center", marginTop: "50px" }}>
                                <Row justify="center" style={{ margin: 0 }}>
                                    <Col xs="content">
                                        <MaoComDinheiro width="89px" />
                                    </Col>
                                </Row>
                                <Row justify="center" style={{ margin: "10px 0px 0px 0px" }}>
                                    <Col xs="content">
                                        <span className="textoArrecadado">VALOR JÁ ARRECADADO</span>
                                    </Col>
                                </Row>
                                <Row justify="center" style={{ margin: "10px 0px 0px 0px" }}>
                                    <Col xs="content">
                                        { this.state.valorArrecadado &&  <span className="textoValorArrecadado">{this.state.valorArrecadado.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>}
                                    </Col>
                                </Row>
                            </Col>

                            {/* Pessoas impactadas */}
                            {/* <Col xs={12} sm={12} md={12} lg={3} xl={3} style={{ padding: 0, textAlign: "left", textAlign: "center", marginTop: "50px" }}>
                                <Row justify="center" style={{ margin: 0 }}>
                                    <Col xs="content">
                                        <Jantar width="89px" />
                                    </Col>
                                </Row>
                                <Row justify="center" style={{ margin: "10px 0px 0px 0px" }}>
                                    <Col xs="content">
                                        <span className="textoArrecadado">ALIMENTOS ARRECADADOS</span>
                                    </Col>
                                </Row>
                                <Row justify="center" style={{ margin: "10px 0px 0px 0px" }}>
                                    <Col xs="content">
                                    <span className="textoValorArrecadado">{ this.state.alimentoArrecadado ?? ""}</span>
                                    </Col>
                                </Row>
                            </Col> */}

                            {/* Quero apoiar */}
                            <Col xs={12} sm={12} md={12} lg={3} xl={3} style={{ padding: 0, marginTop: ['xs', 'sm', 'md'].includes(screenClass) ? "50px" : "110px" }}>
                                <Row justify="center" style={{ margin: 0 }}>
                                    <Col xs="content">
                                        <div onClick={() => { window.location.href = "/doar" }}><ActionButton arrecadado>QUERO APOIAR</ActionButton></div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row >
            )} />
        );
    }
}

export default (Arrecadado);