import React, { Component } from 'react';
import SlideHorizontal from '../../../components/SlideHorizontal';
import Passo from './Passo';
import "./index.css";

class ComoFunciona extends Component {
    constructor(props) {
        super(props)
    }

    passos = [
        {
            numeroPassso: 1,
            titulo: "FAÇA A SUA DOAÇÃO",
            texto: "A sua doação poderá ser feita de forma online, pelo site da campanha."
        },
        {
            numeroPassso: 2,
            titulo: "REPASSE MENSAL",
            texto: "Os recursos arrecadados serão integralmente repassados para os projetos credenciados que trabalham com frentes de combate a fome no Brasil. Eles farão a compra e/ou distribuição dos alimentos diretamente para as famílias necessitadas. "
        },
        {
            numeroPassso: 3,
            titulo: "PRESTAÇÃO DE CONTAS",
            texto: this.props.hasPartner 
            ? "Toda a prestação de contas acontecerá nas redes sociais do Pandemia do Bem. Fique ligado(a) para acompanhar o amor sendo viralizado pela sua doação!" 
            :"Toda a prestação de contas acontecerá em nossas redes sociais. Fique ligado(a) para acompanhar o amor sendo viralizado pela sua doação! "
        }
    ]
    
    listItens = this.passos.map(function (item, i) {
        return <Passo
            numeroPassso={item.numeroPassso}
            titulo={item.titulo}
            texto={item.texto}
        />
    })

    render() {
        return (
            <SlideHorizontal
                itens={this.listItens}
                title="COMO FUNCIONA?"
            />
        );
    }
}

export default (ComoFunciona);