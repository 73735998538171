import React, { Component } from 'react';
import SlideHorizontal from '../../../components/SlideHorizontal';
import Porque from './Porque';
import "./index.css";

const porques = [
    {
        titulo: "19,1 milhões",
        subtitulo: "de pessoas passam fome hoje no Brasil",
        texto: <span>o que corresponde a mais de <br /><span className="textoFrizadoPorques">240 estádios</span><br />do Maracanã lotados.</span>
    },
    {
        titulo: "116,8 milhões",
        subtitulo: "de pessoas sofrem algum tipo de insegurança alimentar no Brasil",
        texto: <span>o que corresponde a mais de <br /><span className="textoFrizadoPorques">6x a população</span><br />do Chile.</span>
    },
    {
        titulo: "12 mil",
        subtitulo: "pessoas, no ano passado, poderiam ter morrido de fome diariamente no mundo devido à pandemia.",
        texto: <span><span className="textoFrizadoPorques">10 mil</span><br />mortes por dia, foi a taxa de mortalidade diária por covid-19 observada globalmente em seu pico, abril de 2020.</span>
    }
]

const listItens = porques.map(function (item, i) {
    return <Porque
        titulo={item.titulo}
        subtitulo={item.subtitulo}
        texto={item.texto}
    />
})

class PorQueAjudar extends Component {

    constructor() {
        super()
    }

    render() {
        return (
            <div style={{marginBottom:"50px"}}>
                <SlideHorizontal
                    itens={listItens}
                    title="POR QUE AJUDAR?"
                />
            </div>
        );
    }
}

export default (PorQueAjudar);