import React, { Component } from 'react';
import { ScreenClassRender } from 'react-grid-system';
import "./index.css";

class ActionButton extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <ScreenClassRender render={screenClass => (
                <div>
                    {
                        this.props.form ?
                            <div
                                className="action-button-box-form"
                                style={{
                                    height:  ["xs", "sm", "md"].includes(screenClass) ? "50px" :"92px"
                                }}>
                                <span className="centerVertical">{this.props.children}</span>
                            </div>
                            :
                            <div className="action-button-box"
                                style={{
                                    borderRadius: ['xs', 'sm', 'md'].includes(screenClass) ? "5px" : "10px",
                                    padding: this.props.arrecadado && ['xs', 'sm', 'md'].includes(screenClass) ? "2vh" : "1.2vw 1vh 1.2vw 1vh"
                                }}>{this.props.children}</div>
                    }
                </div>
            )} />
        );
    }
}

export default (ActionButton);