import React, { Component } from 'react';
import HeaderMenu from '../../components/HeaderMenu';
import Introducao from "./Introducao";
import ComoFunciona from "./ComoFunciona";
import PorQueAjudar from "./PorQueAjudar";
import Arrecadado from "./Arrecadado";
import MetaPalas from "./MetaPalas";
import ProjetosCredenciados from "./ProjetosCredenciados";
import BottomBar from "../../components/BottomBar";
import { firebaseDatabase } from '../../utils';

const COLLECTION_PARCEIROS = "parceiros";

class PandemiaSemFome extends Component {

    constructor(props) {
        super(props);

        let hasPartner = this.checkForPartner();
        let isPalas = this.checkForPalas();
        
        if(isPalas){
            document.title = "Pandemia sem fome - Palas"
        } else{
            document.title = "Pandemia sem fome"
        }

        this.state = {
            hasPartner: hasPartner,
            isPalas: isPalas,
            partnerLoaded: false
        }
    }

    componentDidMount() {
        if (this.state.hasPartner) {
            let url = window.location.pathname;
            let partner = this.extractPartnerNameFromUrl(url);
            this.loadPartner(partner);
        }
    }

    checkForPartner() {
        let url = window.location.pathname;
        return url.includes("partner");
    }

    checkForPalas() {
        let url = window.location.pathname;
        return url.includes("palas");
    }

    extractPartnerNameFromUrl(url) {
        let splitedUrl = url.split("partner/");

        if (splitedUrl.length > 1) {
            return splitedUrl[1];
        }

        //Se n tiver nada dps de partner/
        return "";
    }

    async loadPartner(partner) {
        const parceirosRef = firebaseDatabase.collection(COLLECTION_PARCEIROS);
        const data = await parceirosRef.where("urlPartner", "==", partner).get();

        if (data.docs.length > 0) {
            let doc = data.docs[0].data();

            this.setState({
                partnerLoaded: true,
                partnerText: doc.texto,
                partnerUrl: doc.url,
                partnerLogoUrl: doc.urlLogo
            })
        } else {
            window.location.href = "/fome"
        }
    }

    render() {
        return (
            this.state.hasPartner && !this.state.partnerLoaded
                ?
                <div></div>
                :
                <div>
                    <HeaderMenu fome urlPartnerLogo={this.state.partnerLogoUrl} partnerUrl={this.state.partnerUrl} palas={this.state.isPalas} />
                    <Introducao partnerText={this.state.partnerText} palas={this.state.isPalas} />
                    <ComoFunciona hasPartner={this.state.hasPartner} palas={this.state.isPalas} />
                    <PorQueAjudar />
                    {
                        this.state.isPalas
                            ?
                            <MetaPalas />
                            :
                            <Arrecadado />
                    }
                    <ProjetosCredenciados />
                    <BottomBar />
                </div>
        );
    }
}

export default (PandemiaSemFome);