import React, { Component } from 'react';
import { Row, Col, ScreenClassRender } from 'react-grid-system';
import { Social, Comunidade, Criatividade } from "../../../../components/Icons";

class Conceito extends Component {

    constructor() {
        super()
    }

    render() {
        return (
            <ScreenClassRender render={screenClass => (
                <Row style={{ margin: 0 }}>
                    <Col xs={12} style={{ padding: 0, marginTop: "20px" }}>
                        <p style={{ display: "flex", alignItems: "center" }}>
                            {
                                this.props.icon == "social" ? <Social width={["xs", "sm", "md"].includes(screenClass) ? "20px" : "60px"} />
                                    : this.props.icon == "comunidade" ? <Comunidade width={["xs", "sm", "md"].includes(screenClass) ? "20px" : "60px"} />
                                        : <Criatividade width={["xs", "sm", "md"].includes(screenClass) ? "20px" : "60px"} />
                            }
                            <span style={{
                                fontSize: "max(1vw, 10px)",
                                fontWeight: "900",
                                color: "var(--buttonColor)",
                                marginLeft: ["xs", "sm", "md"].includes(screenClass) ? "5px" : "15px",
                                marginTop: ["xs", "sm", "md"].includes(screenClass) ? "3px" : "10px"
                            }}>{this.props.title}</span>
                        </p>
                        <div style={{
                            fontSize: "max(1vw, 10px)",
                            fontWeight: 600,
                            color: "var(--black)",
                        }}>{this.props.children}</div>
                    </Col>
                </Row >
            )} />
        );
    }
}

export default (Conceito);