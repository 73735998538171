import React, { Component } from 'react';
import { Row, Col, Visible, Hidden } from 'react-grid-system';
import logo from '../../assets/logo.png'
import palasLogo from '../../assets/palasLogo.png'
import ActionButton from '../ActionButton';
import { Menu } from "../Icons";

import "./index.css";

class HeaderMenu extends Component {

    constructor(props) {
        super(props)

        this.state = {
            floatMenuVisible: false
        }
        this.openFloatMenu = this.openFloatMenu.bind(this);
        this.closeFloatMenu = this.closeFloatMenu.bind(this);
    }

    openFloatMenu() {
        this.setState({
            floatMenuVisible: true
        })
    }

    closeFloatMenu() {
        this.setState({
            floatMenuVisible: false
        })
    }

    getHeaderPalas() {
        return (
            <Row className="outer-box-header-menu noselect" justify="center" style={{ margin: 0 }}>
                <Col xs={12} sm={12} md={12} lg={10} xl={9} style={{ padding: "20px 0px 20px 0px" }}>
                    <Row justify="center" className="links-header-menu" style={{ margin: 0 }}>
                        <Hidden xs sm md>
                            <Col className="option-header-menu" xs="content" onClick={() => window.location.href = "/"}><img src={logo} style={{ height: "90px" }} /></Col>
                            <Col className="option-header-menu text-header-menu" style={{ fontSize: "45px" }} xs="content" >&</Col>
                            <Col className="option-header-menu" xs="content" onClick={() => window.location.href = "http://gestaopalas.com.br/"}><img src={palasLogo} style={{ height: "100px" }} /></Col>
                        </Hidden>

                        <Visible xs sm md>
                            <Col className="option-header-menu" xs="content" onClick={() => window.location.href = "/"}><img src={logo} style={{ height: "45px" }} /></Col>
                            <Col className="option-header-menu text-header-menu" style={{ fontSize: "20px" }} xs="content" >&</Col>
                            <Col className="option-header-menu" xs="content" onClick={() => window.location.href = "http://gestaopalas.com.br/"}><img src={palasLogo} style={{ height: "50px" }} /></Col>
                        </Visible>
                    </Row>
                </Col>
            </Row >
        )
    }

    render() {
        if (this.props.palas) {
            return this.getHeaderPalas();
        }

        return (
            <div>
                <div className="floatMenuMobile" style={{ display: this.state.floatMenuVisible ? "block" : "none" }}>
                    <div onClick={() => {
                        this.closeFloatMenu()
                        this.props.goTo(1)
                    }} className="floatMenuOption">
                        Sobre nós
                        <hr />
                    </div>
                    <div onClick={() => {
                        this.closeFloatMenu()
                        this.props.goTo(2)
                    }} className="floatMenuOption">
                        Projetos
                        <hr />
                    </div>
                    <div onClick={() => {
                        this.closeFloatMenu()
                        this.props.goTo(3)
                    }} className="floatMenuOption">
                        Contato
                        <hr />
                    </div>
                </div>
                <div className="floatMenuMobileCloseButton" style={{ display: this.state.floatMenuVisible ? "block" : "none" }}>
                    <div className="closeButton" onClick={this.closeFloatMenu}><span style={{ fontWeight: "500", fontSize: "11px" }}>x</span></div>
                </div>



                <Row className="outer-box-header-menu noselect" justify="center" style={{ margin: 0 }}>
                    <Col xs={12} sm={12} md={12} lg={10} xl={9} style={{ padding: "20px 0px 20px 0px" }}>
                        <Hidden xs sm md>
                            <Row justify="between" className="links-header-menu" style={{ margin: 0 }}>
                                <Col className="option-header-menu" xs="content" onClick={() => this.props.goTo(1)}>{this.props.fome ? "" : "Sobre nós"}</Col>
                                <Col className="option-header-menu" xs="content" onClick={() => this.props.goTo(2)}>{this.props.fome ? "" : "Projetos"}</Col>

                                {
                                    this.props.urlPartnerLogo
                                        ?
                                        <Col
                                            className="option-header-menu"
                                            xs="content"
                                            onClick={() => window.open(this.props.partnerUrl, '_blank').focus()}
                                        >
                                            <img src={this.props.urlPartnerLogo} style={{ height: "90px" }} />
                                        </Col>
                                        : <Col className="option-header-menu" xs="content" onClick={() => window.location.href = "/"}><img src={logo} style={{ height: "90px" }} /></Col>
                                }
                                <Col className="option-header-menu" xs="content" onClick={() => this.props.goTo(3)}>{this.props.fome ? "" : "Contato"}</Col>
                                <Col className="option-header-menu" xs="content" onClick={() => { window.location.href = "/doar" }}><ActionButton>QUERO APOIAR</ActionButton></Col>
                            </Row>
                        </Hidden>

                        <Visible xs sm md>
                            <Row justify="between" className="links-header-menu" style={{ margin: 0, textAlign: "center" }}>
                                <Col xs={3}>
                                    <div className="centerVertical2">
                                        {
                                            this.props.fome
                                                ?
                                                null
                                                :
                                                <div onClick={this.openFloatMenu}><Menu /></div>
                                        }
                                    </div>
                                </Col>

                                {
                                    this.props.urlPartnerLogo ?
                                        <Col
                                            xs="content"
                                            onClick={() => window.open(this.props.partnerUrl, '_blank').focus()}
                                        >
                                            <img src={this.props.urlPartnerLogo} style={{ height: "45px" }} />
                                        </Col>
                                        :
                                        <Col xs="content">
                                            <img onClick={() => window.location.href = "/"} className="centerVertical2" src={logo} style={{ height: " 45px" }} />
                                        </Col>
                                }

                                <Col xs={3} >
                                    <div className="centerVertical2" onClick={() => { window.location.href = "/doar" }}>
                                        <ActionButton>
                                            <span style={{ fontSize: "9px" }}>QUERO APOIAR</span>
                                        </ActionButton>
                                    </div>
                                </Col>
                            </Row>
                        </Visible>
                    </Col>
                </Row >
            </div>
        );
    }
}

export default (HeaderMenu);