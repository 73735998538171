import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import Landing from './pages/Landing';
import PandemiaSemFome from './pages/PandemiaSemFome';
import PoliticaDePrivacidade from "./pages/PoliticaDePrivacidade";
import Doar from "./pages/Doar";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/">
          <Landing />
        </Route>

        <Route path="/fome/partner/">
          <PandemiaSemFome />
        </Route>

        <Route path="/fome">
          <PandemiaSemFome />
        </Route>

        <Route exact path="/politica-de-privacidade">
          <PoliticaDePrivacidade />
        </Route>

        <Route path="/doar">
          <Doar />
        </Route>

        <Redirect to="/" />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
