import React, { Component } from 'react';
import { Row, Col, Hidden, ScreenClassRender } from 'react-grid-system';
import { Email, Instagram, Linkedin, Spotify } from "../Icons";
import logoBranco from "../../assets/logo-branco.png";
import alfaomega from "../../assets/apoio/alfaomega.png"
import jovensmemorial from "../../assets/apoio/jovensMemorial.png"
import "./index.css";

class BottomBar extends Component {

    constructor() {
        super()
    }

    openMail() {
        var email = "pandemiadobem2020@gmail.com"
        var mailto_link = 'mailto:' + email
        window.open(mailto_link, 'emailWindow')
    }

    render() {
        return (
            <ScreenClassRender render={screenClass => (
                <Row style={{ margin: 0 }}>
                    <Col
                        className="bottom-bar-box"
                        xs={12} sm={12} md={12} lg={12} xl={12}
                        style={{
                            padding: ['xs', 'sm', 'md'].includes(screenClass) ? "2vh 0px 2vh 0px" : "5vh 0px 5vh 0px"
                        }}
                    >
                        <Row justify="center" style={{ margin: 0 }}>
                            {/* Logo */}
                            <Hidden xs sm md>
                                <Col xs={3} style={{ padding: 0, textAlign: "left", textAlign: "center" }}>
                                    <img src={logoBranco} width="148px" />
                                </Col>
                            </Hidden>


                            {/* Encontre a gente */}
                            <Col xs={6} sm={6} md={6} lg={3} xl={3} xxl={3} style={{ padding: 0 }}>
                                <Row justify="center" style={{ margin: 0 }}>
                                    <Col xs="content" style={{ padding: 0 }}>
                                        <span className="text-encontre-a-gente">ENCONTRE A GENTE<br /> NO EMAIL OU NAS REDES</span>
                                        <Row style={{ margin: "10px 0px 5px 0px" }}>
                                            <Col xs="content" onClick={this.openMail} style={{ padding: 0, textAlign: "center", marginRight: "15px", cursor: "pointer" }}>
                                                <Email width={['xs', 'sm', 'md'].includes(screenClass) ? "16px" : "30px"} />
                                            </Col>
                                            <Col xs="content" onClick={() => window.open("https://instagram.com/pandemia.do.bem", '_blank').focus()} style={{ padding: 0, textAlign: "center", marginRight: "15px", cursor: "pointer" }}>
                                                <Instagram width={['xs', 'sm', 'md'].includes(screenClass) ? "16px" : "30px"}/>
                                            </Col>
                                            <Col xs="content" onClick={() => window.open("https://www.linkedin.com/company/pandemia-do-bem", '_blank').focus()} style={{ padding: 0, textAlign: "center", marginRight: "15px", cursor: "pointer" }}>
                                                <Linkedin width={['xs', 'sm', 'md'].includes(screenClass) ? "16px" : "30px"}/>
                                            </Col>
                                            <Col xs="content" onClick={() => window.open("https://open.spotify.com/playlist/4WMk2AepfgOZ7ODPrGrHGt?si=dRK03U1eRwC3EMxEsrznAw", '_blank').focus()} style={{ padding: 0, textAlign: "center", cursor: "pointer" }}>
                                                <Spotify width={['xs', 'sm', 'md'].includes(screenClass) ? "16px" : "30px"}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                            {/* Apoiadores */}
                            <Col xs={6} sm={6} md={6} lg={3} xl={3} xxl={3} style={{ padding: 0 }}>
                                <Row justify="center" style={{ margin: 0 }}>
                                    <Col xs="content" style={{ padding: 0 }}>
                                        <span className="text-encontre-a-gente">APOIADORES:</span>
                                        <Row style={{ margin: "10px 0px 5px 0px" }}>
                                            <Col xs="content" onClick={() => window.open("https://www.instagram.com/jovensmemorial/", '_blank').focus()} style={{ padding: 0, textAlign: "center", marginRight: "15px", cursor: "pointer" }}>
                                                <img src={jovensmemorial} width={['xs', 'sm', 'md'].includes(screenClass) ? "24px" : "52px"} style={{ padding: 0 }} />
                                            </Col>
                                            <Col xs="content" onClick={() => window.open("https://www.alfaeomega.com.br", '_blank').focus()} style={{ padding: 0, textAlign: "center", marginRight: "15px", cursor: "pointer" }}>
                                                <img src={alfaomega} width={['xs', 'sm', 'md'].includes(screenClass) ? "52px" : "100px"} style={{ padding: 0, marginTop: ['xs', 'sm', 'md'].includes(screenClass) ? "5px" :"15px" }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row >
            )} />
        );
    }
}

export default (BottomBar);