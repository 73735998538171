import React, { Component } from 'react';
import { Row, Col } from 'react-grid-system';
import Projeto from "./Projeto";
import projetoAbba from "../../../assets/projetosAssociados/abba.png"
import projetoSertao from "../../../assets/projetosAssociados/projetoSertao.jpg"
import todosHaiti from "../../../assets/projetosAssociados/todosHaiti.png"
import "./index.css";

const projetos = [
    {
        imagem: projetoSertao,
        texto: "Projeto com foco no cuidado das comunidades do Sertão da Bahia por meio das doações de colaboradores e voluntários de diversas áreas profissionais.",
        link: "https://www.instagram.com/projeto.sertao/"
    },
    {
        imagem: projetoAbba,
        texto: "Parte da Abba Brasil, propõe articular e efetivar estratégias de cuidado,  condições de escuta, contribuindo para a expressão e o diálogo com as pessoas em situação de risco e vulnerabilidade, priorizando crianças, adolescentes e jovens nas ruas de São Paulo, na praça da Sé, no viaduto da Liberdade e, algumas vezes no Pátio do Colégio.",
        link: "https://www.instagram.com/gentedeperto/"
    },
    {
        imagem: todosHaiti,
        texto: "Uma iniciativa da JOCUM Vila, uma comunidade que trabalha de maneira colaborativa para manifestar o amor de Deus às pessoas, o projeto TODOS NO HAITI alcança o povo Haitiano por meio da doação de alimentos, valorização cultural, cuidado, ensino da palavra de Deus, serviço, apadrinhamentos, entre outras ações.",
        link: "https://jocumvila.org/todos_no_haiti/"
    }
]

class ProjetosCredenciados extends Component {

    constructor() {
        super()
    }

    render() {
        return (
            <Row justify="center" style={{ margin: "5vh 0px 5vh 0px" }}>
                <Col xs={10} sm={10} md={10} lg={8} xl={6} xxl={6} style={{ padding: 0 }}>
                    <Row style={{ margin: 0 }}>
                        {/* Titulo */}
                        <Col xs={12} style={{ padding: 0, textAlign: "center" }}>
                            <div className="tituloProjetosCredenciados">
                                <span>CONHEÇA OS PROJETOS CREDENCIADOS</span>
                            </div>
                        </Col>

                        {
                            projetos.map(function (item, i) {
                                return <Col xs={12} style={{ padding: 0 }}>
                                    <Projeto 
                                        imagem={item.imagem}
                                        texto={item.texto}
                                        link={item.link} 
                                    />
                                </Col>
                            })
                        }
                    </Row>
                </Col>
            </Row >
        );
    }
}

export default (ProjetosCredenciados);