import React, { useState } from 'react';
import { ScreenClassRender } from 'react-grid-system';
import './index.css';

const TextArea = (props) => {

  const [value, setValue] = useState('');

  function handleTextChange(text) {
    setValue(text);
  }

  return (
    <ScreenClassRender render={screenClass => (
      <div id="float-label">
        <textarea
          value={value}
          name={props.name}
          maxlength="600"
          onChange={(e) => {
            handleTextChange(e.target.value);
            props.handleInputChange(e)
          }}
          style={{
            height: ["xs", "sm", "md", "lg"].includes(screenClass) ? "100px" : "200px",
            width:"100%",
            resize: "none"
          }}
        />

        <label style={{ color: "#8B8989" }}>
          {props.label}
        </label>
      </div>
    )} />
  );
}

export default TextArea;