import React, { Component } from 'react';
import { Row, Col, ScreenClassRender } from 'react-grid-system';
import "./index.css";

class Projeto extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <ScreenClassRender render={screenClass => (
                <Row className="borderProjetosCredenciados" style={{ margin: "5vh 0px 0vh 0px", padding: ['xs', 'sm', 'md'].includes(screenClass) ? "4vh" : "2vh", }}>
                    {/* Image */}
                    <Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4} style={{ padding: 0, textAlign: "center" }}>
                        <img src={this.props.imagem} width="80%" style={{ padding: 0, maxWidth: "300px" }} />
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8} style={{
                        padding: 0,
                        textAlign: ['xs', 'sm', 'md'].includes(screenClass) ? "center" : "left",
                        marginTop: ['xs', 'sm', 'md'].includes(screenClass) ? "10px" : ""
                    }}>
                        <div className="centerVertical2">
                            <div className="textoProjetoAssociado">{this.props.texto}</div>
                            <div onClick={() => window.open(this.props.link, '_blank').focus()} className="linkSaibaMais">SAIBA MAIS</div>
                        </div>
                    </Col>
                </Row>
            )} />
        );
    }
}

export default (Projeto);