import React, { Component } from 'react';
import './index.css';

class Checkbox extends Component {

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div>
        <label class="checkbox">
          <input
            type="checkbox"
            name={this.props.name}
            onChange={this.props.handleInputChange}
          />
          <span></span>
        </label>
        <span className="text-checkbox">{this.props.children}</span>
      </div>

    );
  }
}
export default (Checkbox);