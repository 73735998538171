import React from 'react';
import './index.css';
import { Row, Col } from 'react-grid-system';

const ProgressBar = (props) => {

  function getPorcentagemCompleto() {
    let res = ((props.atual / props.total) * 100);
    if (isNaN(res)) {
      return 0;
    }
    return res | 0;
  }

  function getPorcentagemCompletoMax100() {
    let res = getPorcentagemCompleto();
    if(res > 100){
      return 100;
    }

    return res;
  }

  const containerStyles = {
    height: 20,
    width: '100%',
    backgroundColor: "white",
  }

  const fillerStyles = {
    height: '100%',
    width: `${getPorcentagemCompletoMax100()}%`,
    backgroundColor: "var(--red)",
  }

  return (
    <Row justify="center" style={{ margin: 0 }}>
      <Col xs={10} style={{ padding: 0 }}>
        <div style={containerStyles}>
          <div style={fillerStyles}>
          </div>
        </div>
      </Col>
      <Col xs='content' style={{ padding: 0, marginLeft:"20px" }}>
        <span className="textoPorcentagemProgressBar">{getPorcentagemCompleto()}%</span>
      </Col>
    </Row>
  );
}

export default ProgressBar;