import React, { Component } from 'react';
import { Row, Col, ScreenClassRender } from 'react-grid-system';
import prato from "../../../assets/fome/prato.png"
import "./index.css";

class Introducao extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <ScreenClassRender render={screenClass => (
                <div className="introducao-pandemia-sem-fome" style={{ backgroundImage: !this.props.palas ? "none" : "", }}>
                    <Row style={{ margin: 0 }}>
                        <Col xs={7} sm={7} md={6} lg={6} xl={6} xxl={6} style={{ padding: "3vh 0px 3vh 0px" }}>
                            <Row className="centerVertical2" justify="end" style={{ margin: 0 }}>
                                <Col xs={11} sm={11} md={11} lg={11} xl={10} xxl={8} style={{ padding: 0 }}>
                                    <Row style={{ margin: 0 }}>
                                        <Col xs={12} style={{ padding: 0 }}>
                                            <span style={{
                                                fontSize: "max(2vw, 13px)",
                                                fontWeight: 900,
                                                color: 'var(--yellow)'
                                            }}>PANDEMIA SEM FOME</span>
                                        </Col>
                                    </Row>
                                    <Row style={{ margin: "1% 0px 0px 0px" }}>
                                        <Col xs={12} style={{ padding: 0 }}>
                                            <span style={{
                                                fontSize: "max(1.2vw, 10px)",
                                                fontWeight: 700,
                                                color: 'white'
                                            }}>AJUDE A ENCHER UM PRATO VAZIO</span>
                                        </Col>
                                    </Row>
                                    <Row style={{ margin: "4vh 0px 0px 0px" }}>
                                        <Col xs={12} style={{ padding: 0 }}>
                                            <span style={{
                                                fontSize: "max(1.2vw, 10px)",
                                                fontWeight: 600,
                                                color: 'white',
                                                display: "block",
                                                width: "100%",
                                                maxWidth: "600px"
                                            }}>
                                                {
                                                    this.props.partnerText
                                                        ?
                                                        this.props.partnerText
                                                        :
                                                        this.props.palas
                                                            ?
                                                            <>E se a fome fosse um vírus?<br />
                                                                A pandemia mostrou que a fome e o Covid-19 são inimigos que precisamos combater.<br />
                                                                Por isso, a Palas, em parceria com o Pandemia do Bem, irá encher o prato vazio de quem mais precisa.
                                                                Queremos viralizar o amor através de alimento em todo o Brasil.</>
                                                            :
                                                            <>E se a fome fosse um vírus? <br />
                                                            A pandemia mostrou que a fome e o Covid-19 são inimigos que precisamos combater.<br />
                                                            Queremos encher o prato vazio de quem mais precisa e viralizar o amor através de alimento em todo o mundo.</>
                                                }
                                                <br />
                                                <br />
                                                Faça parte você também!
                                            </span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={5} sm={5} md={5} lg={5} xl={4} xxl={4} style={{ padding: 0 }}>
                            <Row className="centerVertical2" justify="start" style={{ margin: 0 }}>
                                <img src={prato} width="100%" style={{ padding: 0 }} />
                            </Row>
                        </Col>
                    </Row >
                </div>
            )} />
        );
    }
}

export default (Introducao);