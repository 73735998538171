import React, { Component } from 'react';
import { ScreenClassRender } from 'react-grid-system';
import "./index.css";

class ImageSelectorDots extends Component {
    constructor(props) {
        super(props)
    }

    getDots(screenClass) {
        let listDots = [];
        let size = ["xs", "sm", "md"].includes(screenClass) ? "5px" : "10px";

        for (let i = 0; i < this.props.numberDots; i++) {
            if (this.props.indexImg == i) {
                listDots.push(<div style={{ width:size, height:size }} className="dotImageSelectorUnactive"></div>);
            } else {
                listDots.push(<div style={{ width:size, height:size }} onClick={() => { this.props.slideRef.current.goTo(i) }} className="dotImageSelectorActive"></div>);
            }
        }

        return listDots;
    }

    render() {
        return (
            <ScreenClassRender render={screenClass => (
                <div>
                    {
                        this.getDots(screenClass)
                    }
                </div>
            )} />
        );
    }
}

export default (ImageSelectorDots);