import React, { Component } from 'react';
import { Row, Col, ScreenClassRender, Hidden, Visible } from 'react-grid-system';
import Input from "../../../components/Input";
import ActionButton from "../../../components/ActionButton";
import Checkbox from "../../../components/Checkbox";
import { firebaseDatabase } from '../../../utils';

import "./index.css";

class Contato extends Component {

    constructor() {
        super()

        this.state = {
            nome: "",
            emailTelefone: "",
            aceito: false,
            enviado: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [target.name]: value
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        let errors = ""

        if (this.state.nome.trim() === "") {
            errors += "Insira seu nome\n"
        }

        if (this.state.emailTelefone.trim() === "") {
            errors += "Insira seu email ou telefone\n"
        }

        if (!this.state.aceito) {
            errors += "É preciso aceitar a política de privacidade para continuar.\n"
        }

        if (errors === "") {

            let collectionName = "cadastros";

            await firebaseDatabase.collection(collectionName).add({
                nome: this.state.nome,
                emailTelefone: this.state.emailTelefone,
                data: new Date(),
            }).then((ref) => {
                this.setState({
                    enviado: true
                })
            }).catch((error) => {
                alert("Ops! Ocorreu algum erro, verifique sua conexão!")
            })
        } else {
            alert(errors)
        }
    }

    openMail() {
        var email = "pandemiadobem2020@gmail.com"
        var mailto_link = 'mailto:' + email
        window.open(mailto_link, 'emailWindow')
    }

    render() {
        return (
            <ScreenClassRender render={screenClass => (
                <Row justify="center" style={{ margin: "50px 0px 50px 0px" }}>
                    <Col className="contato-box" xs={11} sm={11} md={11} lg={10} xl={10} style={{ padding: "5vh 0px 5vh 0px" }}>
                        <Row style={{ margin: 0 }}>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 0, textAlign: "center" }}>
                                <div style={{ fontSize: "max(1.7vw, 14px)", fontWeight: 900, color: "var(--black)" }}>FIQUE CONECTADO!</div>
                            </Col>
                        </Row>

                        <Row style={{ margin: "2vh 0px 2vh 0px" }}>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 0, textAlign: "center" }}>
                                <div style={{ fontSize: "max(1vw, 10px)", fontWeight: 600, color: "var(--black)" }}>DEIXE SEU WHATSAPP OU EMAIL PARA RECEBER AS NOVIDADES.</div>
                            </Col>
                        </Row>
                        {
                            this.state.enviado
                                ?
                                <Row justify="center" style={{ margin: 0 }}>
                                    <Col xs="content" style={{ padding: 0, textAlign: "center" }}>
                                        <div
                                            style={{
                                                fontSize: "max(1vw, 10px)",
                                                fontWeight: 600,
                                                color: "white",
                                                backgroundColor:"var(--buttonColor)",
                                                width:"fit-content",
                                                textAlign:"center",
                                                padding:"5vh",
                                                borderRadius:"10px"
                                            }}>REGISTRAMOS SEU CADASTRO COM SUCESSO!</div>
                                    </Col>
                                </Row>
                                :
                                <Row justify="center" style={{ margin: 0 }}>
                                    <Col xs={11} sm={11} md={11} lg={11} xl={5} style={{
                                        padding: 0,
                                        textAlign: "center",
                                        margin: ["xs", "sm", "md", "lg"].includes(screenClass) ? "2vh 0px 0px 0px" : "20px 20px 0px 0px"
                                    }}>
                                        <Input
                                            label="NOME"
                                            name="nome"
                                            handleInputChange={this.handleInputChange}
                                        ></Input>
                                    </Col>
                                    <Col xs={11} sm={11} md={11} lg={11} xl={4} style={{
                                        padding: 0,
                                        textAlign: "center",
                                        margin: ["xs", "sm", "md", "lg"].includes(screenClass) ? "2vh 0px 0px 0px" : "20px 20px 0px 0px"
                                    }}>
                                        <Input
                                            label="EMAIL OU CELULAR"
                                            name="emailTelefone"
                                            handleInputChange={this.handleInputChange}
                                        ></Input>
                                    </Col>
                                    <Hidden xs sm md lg>
                                        <Col xs={2} sm={2} md={2} lg={2} xl={1} style={{
                                            padding: 0,
                                            textAlign: "center",
                                            margin: ["xs", "sm", "md", "lg"].includes(screenClass) ? "2vh 0px 0px 0px" : "20px 20px 0px 0px"
                                        }}
                                            onClick={this.handleSubmit}
                                        >
                                            <ActionButton form={true}>OK</ActionButton>
                                        </Col>
                                    </Hidden>

                                    <Col xs={9} sm={9} md={9} lg={9} xl={10} style={{ padding: 0, margin: ["xs", "sm", "md", "lg"].includes(screenClass) ? "20px 0px 0px 0px" : "20px 55px 0px 0px" }}>
                                        <Checkbox
                                            name="aceito"
                                            aceito={this.state.aceito}
                                            handleInputChange={this.handleInputChange}
                                        >
                                            Leia nossa <u onClick={() => { window.location.href = "/politica-de-privacidade" }} style={{ cursor: "pointer" }} >Política de Privacidade.</u>
                                        </Checkbox>
                                    </Col>

                                    <Visible xs sm md lg>
                                        <Col xs={2} sm={2} md={2} lg={2} xl={1} style={{
                                            padding: 0,
                                            textAlign: "center",
                                            margin: ["xs", "sm", "md", "lg"].includes(screenClass) ? "2vh 0px 0px 0px" : "20px 20px 0px 0px"
                                        }}
                                            onClick={this.handleSubmit}
                                        >
                                            <ActionButton form={true}>OK</ActionButton>
                                        </Col>
                                    </Visible>
                                </Row>
                        }

                    </Col>
                </Row >
            )} />
        );
    }
}

export default (Contato);