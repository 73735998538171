import React, { useRef } from 'react';
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system';
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';
import { PrevArrow, NextArrow } from "../../../components/Icons";
import "./index.css";
import OutlinedButton from "../../../components/OutlinedButton";

import hands from '../../../assets/slideImages/hands.jpg'
import prato from '../../../assets/slideImages/prato.png'

import ImageSelectorDots from "./ImageSelectorDots";

const SlideImagesText = () => {
    const slideRef = useRef();

    const back = () => {
        slideRef.current.goBack();
    }

    const next = () => {
        slideRef.current.goNext();
    }

    return (
        <ScreenClassRender render={screenClass => (
            <Row style={{ margin: 0 }}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 0 }} className="container-slide-images">
                    <div className="prevArrow" style={{
                        marginTop: ["xs", "sm", "md"].includes(screenClass) ? "18vh" : "45vh"
                    }} onClick={back}>
                        <PrevArrow width={["xs", "sm", "md"].includes(screenClass) ? "8" : "26"} height={["xs", "sm", "md"].includes(screenClass) ? "18" : "41"} />
                    </div>
                    <div className="nextArrow" style={{
                        marginTop: ["xs", "sm", "md"].includes(screenClass) ? "18vh" : "45vh",
                        marginLeft: ["xs", "sm", "md"].includes(screenClass) ? "calc(95vw - 8px)" : "calc(95vw - 40px)"
                    }} onClick={next}>
                        <NextArrow width={["xs", "sm", "md"].includes(screenClass) ? "8" : "26"} height={["xs", "sm", "md"].includes(screenClass) ? "18" : "41"} />
                    </div>
                    <Slide ref={slideRef} easing="linear" arrows={false} canSwipe={false} duration={7000} pauseOnHover={false}>
                        <div className="each-slide">
                            <div className="slideImage" style={{ 'backgroundImage': `url(${hands})`, height: ["xs", "sm", "md"].includes(screenClass) ? "40vh" : "100vh" }}>
                                <div className="shadoBoxSlideImage" style={{ backgroundColor: "rgba(242, 46, 46, .5)" }}>
                                    <div className="boxTextoSlides centerText centerVertical2" style={{}}>
                                        <div style={{ fontSize: "max(1.3vw, 10px)", color: 'white', fontWeight: 600 }}>BEM VINDO AO PANDEMIA DO BEM</div>
                                        <div style={{ fontSize: "max(2.4vw, 14px)", color: 'white', fontWeight: 900, marginTop: "2vh", marginBottom: "2vh" }}>E SE O AMOR FOSSE UM VÍRUS?</div>

                                        <ImageSelectorDots numberDots={2} indexImg={0} slideRef={slideRef} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="each-slide">
                            <div className="slideImage" style={{ 'backgroundImage': `url(${prato})`, height: ["xs", "sm", "md"].includes(screenClass) ? "40vh" : "100vh" }}>
                                <div className="shadoBoxSlideImage">
                                    <div className="boxTextoSlides centerVertical2">
                                        <div style={{ fontSize: "max(1.3vw, 10px)", color: 'var(--red)', fontWeight: 700 }}>PANDEMIA SEM FOME</div>
                                        <div style={{ fontSize: "max(2.4vw, 14px)", color: 'var(--black)', fontWeight: 900, marginTop:"2vh", marginBottom: "2vh " }}>AJUDE A ENCHER UM PRATO VAZIO</div>

                                        <OutlinedButton color="var(--red)" onClick={() => { window.location.href = "/fome" }}>SAIBA MAIS</OutlinedButton>
                                        <div className="centerText">
                                            <ImageSelectorDots numberDots={2} indexImg={1} slideRef={slideRef} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slide>

                </Col>
            </Row >
        )} />
    );
}

export default (SlideImagesText);