import React, { Component } from 'react';
import HeaderMenu from '../../components/HeaderMenu';
import BottomBar from "../../components/BottomBar";
import Pix from './Pix';

class Doar extends Component {
    constructor(props){
        super(props);
        
        let hasPartner = this.checkForPartner();

        this.state = {
            hasPartner: hasPartner
        }
    }

    componentDidMount(){
        if(this.state.hasPartner){
            document.title = "Doar - Palas"
        } else{
            document.title = "Doar"
        }
    }
    
    checkForPartner(){
        let url = window.location.pathname;
        return url.includes("palas");
    }

    render() {
        return (
            <div>
                <HeaderMenu fome palas={this.state.hasPartner}/>
                <Pix palas={this.state.hasPartner}/>
                <BottomBar />
            </div>
        );
    }
}

export default Doar;