import React, { Component } from 'react';
import { Row, Col } from 'react-grid-system';
import { DownArrow } from '../../../../components/Icons';
import "./index.css";

class Porque extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div style={{ height: "100%" }}>
                <div className="backgroundTituloPorqueAjudar">
                    <Row justify="center" style={{ margin: 0 }}>
                        <Col xs={12} style={{ padding: 0 }}>
                            <div className="tituloPorqueAjudar">
                                <span>{this.props.titulo}</span>
                            </div>
                        </Col>

                        <Col xs={9} style={{ padding: 0 }}>
                            <div className="subtituloPorqueAjudar">
                                <span> {this.props.subtitulo} </span>
                            </div>
                        </Col>
                    </Row >
                </div>
                <div style={{ position: "relative", left: "calc(50% - 11px)", width:"fit-content", float:"left", marginTop:"-8px"}}>
                    <DownArrow />
                </div>
                <div className="backgroundTextoPorqueAjudar">
                    <Row justify="center" style={{ margin: 0, width:"100%"}}>
                        <Col xs={10} style={{ padding: 0,}}>
                            <div className="textoPorqueAjudar">
                                <span>{this.props.texto}</span>
                            </div>
                        </Col>
                    </Row >
                </div>
            </div>
        );
    }
}

export default (Porque);