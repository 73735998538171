import React, { Component } from 'react';
import "./index.css";

class OutlinedButton extends Component {

    constructor() {
        super()
    }

    render() {
        return (
            <div 
            onClick={this.props.onClick} 
            className="outlined-button-box"
            style={{
                border: "1px solid "+this.props.color,
                color: this.props.color
            }}
            >{this.props.children}</div>
        );
    }
}

export default (OutlinedButton);