import React, { Component } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import Causa from './Causa';
import "./index.css";
import { Fome, Pobreza, Saude, Criancas, Pandemia, SaudeMental, Renda, NutricaoInfantil, VulnerabilidadeSocial } from "../../../components/Icons";

const causas = [
    {
        nome: "CRIANÇAS",
        icone: <Criancas/>
    },
    {
        nome: "FOME",
        icone: <Fome/>
    },
    {
        nome: "GERAÇÃO DE RENDA",
        icone: <Renda/>
    },
    {
        nome: "NUTRIÇÃO INFANTIL",
        icone: <NutricaoInfantil/>
    },
    {
        nome: "PANDEMIA",
        icone: <Pandemia/>
    },
    {
        nome: "POBREZA",
        icone: <Pobreza/>
    },
    {
        nome: "SAÚDE",
        icone: <Saude/>
    },
    
    {
        nome: "SAÚDE MENTAL",
        icone: <SaudeMental/>
    },
    {
        nome: "VULNERAB. SOCIAL",
        icone: <VulnerabilidadeSocial/>
    }
]

class NossasCausas extends Component {

    constructor() {
        super()
    }

    render() {
        return (
            <Row style={{ margin: 0, marginBottom: "50px" }}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 0 }}>
                    <Row style={{ margin: 0, marginTop: "50px", marginBottom: "50px" }}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 0, textAlign: "center" }}>
                            <span style={{ fontSize: "max(1.7vw, 13px)", fontWeight: 800, color: 'var(--black)' }}>NOSSAS CAUSAS</span>
                        </Col>
                    </Row>

                    <Row justify="center" style={{ margin: 0 }}>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11} style={{ padding: 0 }}>
                            <Row justify="between" style={{ margin: 0 }}>
                                {
                                    causas.map(function (item, i) {
                                        console.log('test');
                                        return <Col xs={i <= 3 ? 12/4 : 12/5} sm={i <= 3 ? 12/4 : 12/5} md={i <= 3 ? 12/4 : 12/5} lg={i <= 3 ? 3 : 12/5} xl={12 / 9} style={{ padding: 0, marginTop: "20px", textAlign: "center" }}>
                                            <Causa icon={item.icone}>{item.nome}</Causa>
                                        </Col>
                                    })
                                }
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row >
        );
    }
}

export default (NossasCausas);