import React from 'react';
import { Row, Col, Hidden, Visible, ScreenClassRender } from 'react-grid-system';
import pixQrCode from '../../../assets/qr_code_pix.png';
import Mensagem from './Mensagem';
import "./index.css";

function Pix(props) {
    return (
        <ScreenClassRender render={screenClass => (
            <Row style={{
                width: "100%",
                margin: 0
            }}>
                <Col xs={12} style={{ padding: 0 }}>
                    {/* PIX */}
                    <Row justify="center" style={{
                        margin: 0,
                        height: ['xs', 'sm'].includes(screenClass) ? "40vh" : "auto",
                        marginBottom: ['xs', 'sm'].includes(screenClass) ? "max(15vh, 175px)" : "0vh",
                        minHeight: "300px",
                        backgroundImage: ['xs', 'sm'].includes(screenClass) || !props.palas ? "none" : "",
                    }} className="pixBackground">
                        <Col xs={12} sm={12} md={12} lg={10} xl={10} style={{ padding: 0 }}>
                            <Row justify="center" style={{ margin: 0 }}>
                                <Col xs={10} sm={10} md={6} lg={6} xl={6} style={{ padding: 0, textAlign: ['xs', 'sm'].includes(screenClass) ? "center" : "left" }}>
                                    <Col xs={12} style={{ padding: 0 }}>
                                        <Visible sm xs>
                                            <div style={{ fontSize: "max(2.1vw, 18px)", fontWeight: 800, color: '#F2C641', display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                DOE COM
                                                <span style={{ fontSize: "max(5.8vw, 60px)", fontWeight: 900, color: '#F2D4AE', marginLeft: "10px" }}>PIX</span>
                                            </div>
                                        </Visible>
                                        <Hidden sm xs>
                                            <div style={{ fontSize: "max(2.1vw, 14px)", fontWeight: 800, color: '#F2C641' }}>
                                                DOE COM
                                            </div>
                                            <div style={{ fontSize: "max(5.8vw, 40px)", fontWeight: 900, color: '#F2D4AE', marginTop: "-1vw", marginLeft: "-3px" }}>PIX</div>
                                        </Hidden>
                                    </Col>
                                    <Col xs={12} style={{ padding: 0, textAlign: "", marginTop: "20px" }}>
                                        <span style={{ fontSize: "max(1.2vw, 14px)", fontWeight: 600, color: 'white' }}>Nossa chave:</span>
                                        <Hidden sm xs>
                                            <br />
                                            <br />
                                        </Hidden>
                                        <br />
                                        <span style={{ fontSize: "max(1.6vw, 14px)", fontWeight: 800, color: 'white' }}>pandemiadobem2020@gmail.com</span>
                                        <Hidden sm xs>
                                            <br />
                                        </Hidden>
                                        <br />
                                        <br />
                                        {
                                            props.palas
                                                ?
                                                <span style={{ fontSize: "max(1vw, 12px)", fontWeight: "normal", color: 'white' }}>
                                                    ATENÇÃO:  Por favor, escreva na descrição do PIX: <b>Palas</b>.
                                                </span>
                                                :
                                                <span style={{ fontSize: "max(1vw, 12px)", fontWeight: "normal", color: 'white' }}>Se o teu apoio for para campanha Pandemia sem Fome,
                                                    por favor, identifique ao realizar a doação.</span>
                                        }

                                    </Col>
                                </Col>
                                {/* QR CODE */}
                                <Col xs={10} sm={10} md={4} lg={4} xl={4} style={{ padding: 0, marginTop: ['xs', 'sm'].includes(screenClass) ? "max(20vh, 220px)" : "0px", position: ['xs', 'sm'].includes(screenClass) ? "absolute" : "initial" }}>
                                    <Row justify="center" style={{ margin: 0 }}>
                                        <Col xs="content" style={{ padding: 0, textAlign: "center" }}>
                                            <div className="qrcodeBack" style={{ width: "100%" }}>
                                                <div style={{ fontSize: "max(1.3vw, 16px)", fontWeight: 800, color: '#F2C641' }}>FAÇA O PIX<br />VIA QR CODE</div>
                                                <img style={{ marginTop: "1vw" }} src={pixQrCode} width={['xs', 'sm'].includes(screenClass) ? "200px" : "200px"} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row >

                    {/* Mensagem */}
                    <Row justify="center" style={{ margin: ['xs', 'sm'].includes(screenClass) ? "-5vh 0 0 0" : "-10vh 0px 0px 0px" }}>
                        <Col xs={12} sm={12} md={10} lg={10} xl={10} style={{ padding: 0, textAlign: "center" }}>
                            <Mensagem />
                        </Col>
                    </Row>
                </Col>
            </Row>
        )} />
    );
}

export default Pix;