import firebase from 'firebase';
import "firebase/auth";
import "firebase/analytics";

const configQA = {
    apiKey: "AIzaSyAmk20WJdTSWKt3Y4Okwu97K_eLAWEY-Gc",
    authDomain: "pandemia-do-bem-qa.firebaseapp.com",
    projectId: "pandemia-do-bem-qa",
    storageBucket: "pandemia-do-bem-qa.appspot.com",
    messagingSenderId: "566084638979",
    appId: "1:566084638979:web:c8720100f6594c716cf136",
    measurementId: "G-TQ9RW6FVJ5"
};

const configProd = {
    apiKey: "AIzaSyDQ7fPLD_565zEHRPy-7468wTYBMoa_pKA",
    authDomain: "pandemia-do-bem.firebaseapp.com",
    projectId: "pandemia-do-bem",
    storageBucket: "pandemia-do-bem.appspot.com",
    messagingSenderId: "727710460948",
    appId: "1:727710460948:web:1074403849e7411dc3cb01",
    measurementId: "G-B72E76555Y"
  };

export const firebaseImpl = firebase.initializeApp(configProd);
export const firebaseDatabase = firebase.firestore();
export const auth = firebase.auth();
export const analytics = firebase.analytics()