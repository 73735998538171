import React, { Component } from 'react';
import HeaderMenu from '../../components/HeaderMenu';
import SlideImagesText from './SlideImagesText';
import SobreNos from './SobreNos';
import NossasCausas from './NossasCausas';
import Projetos from "./Projetos";
import BottomBar from "../../components/BottomBar";
import Contato from "./Contato";

class Landing extends Component {

    constructor() {
        super()
        document.title = "Página inicial"
        this.sobrenos = React.createRef();
        this.projetos = React.createRef();
        this.contato = React.createRef();
        this.scrollToContent = this.scrollToContent.bind(this);
    }

    scrollToContent(content) {
        switch (content) {
            case 1:
                this.sobrenos.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 2:
                this.projetos.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 3:
                this.contato.current.scrollIntoView({ behavior: 'smooth' });
                break;
        }
    }

    render() {
        return (
            <div>
                <HeaderMenu
                    goTo={this.scrollToContent}
                />
                <SlideImagesText />
                <div ref={this.sobrenos} />
                <SobreNos />
                <NossasCausas />
                <div ref={this.projetos} />
                <Projetos />
                {/* <Apoio /> */}
                <div ref={this.contato} />
                <Contato />
                <BottomBar />
            </div>
        );
    }
}

export default (Landing);