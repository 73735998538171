import React, { Component } from 'react';
import { Row, Col, ScreenClassRender, Hidden, Visible } from 'react-grid-system';
import Input from "../../../../components/Input";
import TextArea from "../../../../components/TextArea";
import ActionButton from "../../../../components/ActionButton";
import Checkbox from "../../../../components/Checkbox";
import { firebaseDatabase } from '../../../../utils';
import "./index.css";

class Mensagem extends Component {

    constructor() {
        super()

        this.state = {
            nome: "",
            email: "",
            cidade: "",
            mensagem: "",
            aceito: false,
            enviado: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [target.name]: value
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        let errors = ""

        if (this.state.nome.trim() === "") {
            errors += "Insira seu nome\n"
        }

        if (this.state.email.trim() === "") {
            errors += "Insira seu email\n"
        }

        if (this.state.cidade.trim() === "") {
            errors += "Insira sua cidade\n"
        }

        if (this.state.mensagem.trim() === "") {
            errors += "Insira uma mensagem\n"
        }

        if (!this.state.aceito) {
            errors += "É preciso aceitar a política de privacidade para continuar.\n"
        }

        if (errors === "") {

            let collectionName = "mensagens";

            await firebaseDatabase.collection(collectionName).add({
                nome: this.state.nome,
                email: this.state.email,
                cidade: this.state.cidade,
                mensagem: this.state.mensagem,
                data: new Date(),
            }).then((ref) => {
                this.setState({
                    enviado: true
                })
            }).catch((error) => {
                console.log(error);
                alert("Ops! Ocorreu algum erro, verifique sua conexão!")
            })
        } else {
            alert(errors)
        }
    }

    render() {
        return (
            <ScreenClassRender render={screenClass => (
                <Row justify="center" style={{ margin: "50px 0px 50px 0px" }}>
                    <Col className="contato-box" xs={11} sm={11} md={11} lg={12} xl={12} style={{ padding: "5vh 0px 5vh 0px" }}>
                        <Row style={{ margin: 0 }}>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 0, textAlign: "center" }}>
                                <div style={{ fontSize: "max(1.7vw, 14px)", fontWeight: 900, color: "var(--black)" }}>APROVEITE E VIRALIZE O AMOR!</div>
                            </Col>
                        </Row>

                        <Row justify="center" style={{ margin: "2vh 0px 2vh 0px" }}>
                            <Col xs={10} sm={10} md={10} lg={6} xl={6} style={{ padding: 0, textAlign: "center" }}>
                                <div style={{ fontSize: "max(1vw, 10px)", fontWeight: 600, color: "var(--black)" }}>Escreva uma mensagem de esperança para uma pessoa que está em situaçao de
                                    vulnerabilidade alimentar no Brasil e viralize o amor com a gente!</div>
                            </Col>
                        </Row>
                        {
                            this.state.enviado
                                ?
                                <Row justify="center" style={{ margin: 0 }}>
                                    <Col xs="content" style={{ padding: 0, textAlign: "center" }}>
                                        <div
                                            style={{
                                                fontSize: "max(1vw, 10px)",
                                                fontWeight: 600,
                                                color: "white",
                                                backgroundColor: "var(--buttonColor)",
                                                width: "fit-content",
                                                textAlign: "center",
                                                padding: "5vh",
                                                borderRadius: "10px"
                                            }}>REGISTRAMOS SUA MENSAGEM COM SUCESSO!</div>
                                    </Col>
                                </Row>
                                :
                                <Row justify="center" style={{ margin: 0 }}>
                                    <Row justify="between" style={{ width: "95%" }}>
                                        <Col xs={12} sm={12} md={12} lg={3.9} xl={3.9} style={{
                                            padding: 0,
                                            textAlign: "center",
                                            margin: ["xs", "sm", "md"].includes(screenClass) ? "2vh 0px 0px 0px" : "20px 0px 0px 0px"
                                        }}>
                                            <Input
                                                label="NOME"
                                                name="nome"
                                                handleInputChange={this.handleInputChange}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={3.9} xl={3.9} style={{
                                            padding: 0,
                                            textAlign: "center",
                                            margin: ["xs", "sm", "md"].includes(screenClass) ? "2vh 0px 0px 0px" : "20px 0px 0px 0px"
                                        }}>
                                            <Input
                                                label="EMAIL"
                                                name="email"
                                                handleInputChange={this.handleInputChange}
                                            />
                                        </Col>

                                        <Col xs={12} sm={12} md={12} lg={3.9} xl={3.9} style={{
                                            padding: 0,
                                            textAlign: "center",
                                            margin: ["xs", "sm", "md"].includes(screenClass) ? "2vh 0px 0px 0px" : "20px 0px 0px 0px"
                                        }}>
                                            <Input
                                                label="CIDADE"
                                                name="cidade"
                                                handleInputChange={this.handleInputChange}
                                            />
                                        </Col>
                                    </Row>
                                    <Row justify="center" style={{ width: "95%" }}>
                                        <Col xs={12} style={{
                                            padding: 0,
                                            marginTop:"20px"
                                        }}>
                                            <TextArea
                                                label="DEIXE AQUI SUA MENSAGEM"
                                                name="mensagem"
                                                handleInputChange={this.handleInputChange}
                                            />
                                        </Col>
                                    </Row>
                                    <Row justify="between" style={{ width: "95%" }}>
                                        <Col xs="content" style={{
                                            padding: 0,
                                            margin: ["xs", "sm", "md"].includes(screenClass) ? "20px 0px 0px 0px" : "20px 0px 0px 0px"
                                        }}>
                                            <Checkbox
                                                name="aceito"
                                                aceito={this.state.aceito}
                                                handleInputChange={this.handleInputChange}
                                            >
                                                Leia nossa <u onClick={() => { window.location.href = "/politica-de-privacidade" }} style={{ cursor: "pointer" }} >Política de Privacidade.</u>
                                            </Checkbox>
                                        </Col>

                                        <Col xs={2} sm={2} md={2} lg={2} xl={1} style={{
                                            padding: 0,
                                            textAlign: "center",
                                            margin: ["xs", "sm", "md"].includes(screenClass) ? "2vh 0px 0px 0px" : "20px 20px 0px 0px"
                                        }}
                                            onClick={this.handleSubmit}
                                        >
                                            <ActionButton form={true}>OK</ActionButton>
                                        </Col>
                                    </Row>
                                </Row>
                        }
                    </Col>
                </Row >
            )} />
        );
    }
}

export default (Mensagem);