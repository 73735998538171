import React, { Component } from 'react';
import { Row, Col } from 'react-grid-system';
import "./index.css";

import prato from '../../../assets/prato.png'
import amazonas from '../../../assets/projetos/amazonas.png'
import bigodinho from '../../../assets/projetos/bigodinho.jpg'
import genteDePerto from '../../../assets/projetos/genteDePerto.jpg'
import mocambique from '../../../assets/projetos/mocambique.jpg'
import pac from '../../../assets/projetos/pac.JPG'
import proViver from '../../../assets/projetos/proViver.jpg'

import OutlinedButton from "../../../components/OutlinedButton";

const projetos = [
    { nome: "MOÇAMBIQUE", descricao: "Doação de máscaras", image: mocambique},
    { nome: "PAC", descricao: "Alimentação para abrigo", image: pac },
    { nome: "BIGODINHO DE LEITE", descricao: "Alimentação para o Haiti", image: bigodinho },
    { nome: "PROVIVER", descricao: "Doação de sacolas de natal", image: proViver },
    { nome: "AMAZONAS", descricao: "Doação de oxigênio", image: amazonas },
    { nome: "GENTE DE PERTO", descricao: "Kit para o dia da mulher", image: genteDePerto },
]

class Projetos extends Component {

    constructor() {
        super()
    }

    render() {
        return (
            <Row justify="center" style={{ margin: 0}}>
                <Col xs={11} sm={11} md={11} lg={10} xl={10} style={{ padding: 0 }}>
                    <Row style={{ margin: 0, marginTop: "0px", marginBottom: "50px" }}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 0, textAlign: "center" }}>
                            <span style={{ fontSize: "max(2vw, 14px)", fontWeight: 900, color: 'var(--black)' }}>PROJETOS</span>
                        </Col>
                    </Row>

                    <Row style={{ margin: 0 }}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 0 }}>
                            <div className="projeto-pandemia-sem-fome" style={{ 'backgroundImage': `url(${prato})`, cursor:"pointers" }}>
                                <div className="shadowBoxProjetos">
                                    <div className="texto-projeto-pandemia-sem-fome">
                                        <div style={{ color: "white", fontSize: "max(1vw, 11px)", fontWeight: "900" }}>PANDEMIA SEM FOME</div>
                                        <div style={{ color: "white", fontSize: "max(0.9vw, 10px)", fontWeight: "500", marginTop:"2vh" }}>Arrecadação de cestas básicas</div>
                                        <div style={{ marginTop:"2vh"}}s><OutlinedButton  color="white" onClick={() => { window.location.href = "/fome" }}>SAIBA MAIS</OutlinedButton></div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row justify="between" style={{ margin: 0, width:"calc(100% + 2px)"}}>
                        {
                            projetos.map((item, index) => (
                                <Col xs={4} sm={4} md={4} lg={4} xl={4} style={{ padding: "2px 0px 0px 0px" }}>
                                    <div className="outros-projetos" style={{ 'backgroundImage': `url(${item.image})` }}>
                                        <div className="shadowBoxProjetos">
                                            <div className="texto-projetos">
                                                <div style={{ color: "white", fontSize: "max(1vw, 11px)", fontWeight: "900" }}>{item.nome}</div>
                                                <div style={{ color: "white", fontSize: "max(0.9vw, 10px)", fontWeight: "500", marginTop:"2vh" }}>{item.descricao}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            ))
                        }

                    </Row>
                </Col>
            </Row >
        );
    }
}

export default (Projetos);