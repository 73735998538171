import React, { Component } from 'react';
import social from '../../assets/icons/social.png'
import criatividade from '../../assets/icons/criatividade.png'
import comunidade from '../../assets/icons/comunidade.png'

import fome from '../../assets/icons/fome.png'
import comida from '../../assets/icons/comida.png'
import criancas from '../../assets/icons/criancas.png'
import maoamiga from '../../assets/icons/mao-amiga.png'
import mascara from '../../assets/icons/mascara.png'
import pobreza from '../../assets/icons/pobreza.png'
import saudemental from '../../assets/icons/saude-mental.png'
import saude from '../../assets/icons/saude.png'
import renda from '../../assets/icons/renda.png'

import whats from '../../assets/icons/whatsapp.png'

import insta from '../../assets/icons/instagram.png'
import linke from '../../assets/icons/linkedin.png'
import email from '../../assets/icons/email.png'
import spotify from "../../assets/icons/spotify.png"

import maoComDinheiro from '../../assets/icons/mao-com-dinheiro.png'
import grupoPessoas from '../../assets/icons/group-de-pessoas.png'
import jantar from '../../assets/icons/jantar.png'

import { ScreenClassRender } from 'react-grid-system';

class Menu extends Component {

    render() {
        return (
            <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0H18V2H0V0ZM0 5H18V7H0V5ZM0 10H18V12H0V10Z" fill="#9E9E9E" />
            </svg>

        );
    }
}

class MaoComDinheiro extends Component {

    render() {
        return (
            <img src={maoComDinheiro} width={this.props.width} style={{ padding: 0 }} />
        );
    }
}

class GrupoDePessoas extends Component {

    render() {
        return (
            <img src={grupoPessoas} width={this.props.width} style={{ padding: 0 }} />
        );
    }
}

class Jantar extends Component {

    render() {
        return (
            <img src={jantar} width={this.props.width} style={{ padding: 0 }} />
        );
    }
}

class PrevArrow extends Component {

    render() {
        return (
            //  width="26" height="41"
            <svg width={this.props.width} height={this.props.height} viewBox="0 0 26 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.875 17.875C-0.25 19.125 -0.25 21 0.875 22.125L17.875 39.125C19.125 40.375 21 40.375 22.125 39.125L25 36.375C26.125 35.125 26.125 33.25 25 32.125L12.875 20L25 8C26.125 6.875 26.125 4.875 25 3.75L22.125 0.875C21 -0.25 19.125 -0.25 17.875 0.875L0.875 17.875Z" fill="white" />
            </svg>
        );
    }
}

class NextArrow extends Component {

    render() {
        return (
            <svg width={this.props.width} height={this.props.height} viewBox="0 0 26 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25 22.125C26.125 21 26.125 19.125 25 17.875L8 0.875C6.75 -0.25 4.875 -0.25 3.75 0.875L0.875 3.75C-0.25 5 -0.25 6.875 0.875 8L13 20.125L0.875 32.125C-0.25 33.25 -0.25 35.125 0.875 36.375L3.75 39.125C4.875 40.375 6.75 40.375 8 39.125L25 22.125Z" fill="white" />
            </svg>
        );
    }
}

class Social extends Component {

    render() {
        return (
            <img src={social} width={this.props.width} />
        );
    }
}

class Comunidade extends Component {

    render() {
        return (
            <img src={comunidade} width={this.props.width} />
        );
    }
}

class Criatividade extends Component {

    render() {
        return (
            <img src={criatividade} width={this.props.width} />
        );
    }
}

class Fome extends Component {

    render() {
        return (
            <ScreenClassRender render={screenClass => (
                <img src={fome} width={["xs", "sm", "md"].includes(screenClass) ? "30px" : "60px"} style={{ padding: 0 }} />
            )} />
        );
    }
}

class Pobreza extends Component {

    render() {
        return (
            <ScreenClassRender render={screenClass => (
                <img src={pobreza} width={["xs", "sm", "md"].includes(screenClass) ? "30px" : "60px"} style={{ padding: 0 }} />
            )} />
        );
    }
}

class Saude extends Component {

    render() {
        return (
            <ScreenClassRender render={screenClass => (
                <img src={saude} width={["xs", "sm", "md"].includes(screenClass) ? "30px" : "60px"} style={{ padding: 0 }} />
            )} />
        );
    }
}

class Criancas extends Component {

    render() {
        return (
            <ScreenClassRender render={screenClass => (
                <img src={criancas} width={["xs", "sm", "md"].includes(screenClass) ? "30px" : "60px"} style={{ padding: 0 }} />
            )} />
        );
    }
}

class Pandemia extends Component {

    render() {
        return (
            <ScreenClassRender render={screenClass => (
                <img src={mascara} width={["xs", "sm", "md"].includes(screenClass) ? "30px" : "60px"} style={{ padding: 0 }} />
            )} />
        );
    }
}

class SaudeMental extends Component {

    render() {
        return (
            <ScreenClassRender render={screenClass => (
                <img src={saudemental} width={["xs", "sm", "md"].includes(screenClass) ? "30px" : "60px"} style={{ padding: 0 }} />
            )} />
        );
    }
}

class Renda extends Component {

    render() {
        return (
            <ScreenClassRender render={screenClass => (
                <img src={renda} width={["xs", "sm", "md"].includes(screenClass) ? "30px" : "60px"} style={{ padding: 0 }} />
            )} />
        );
    }
}

class NutricaoInfantil extends Component {

    render() {
        return (
            <ScreenClassRender render={screenClass => (
                <img src={comida} width={["xs", "sm", "md"].includes(screenClass) ? "30px" : "60px"} style={{ padding: 0 }} />
            )} />
        );
    }
}

class VulnerabilidadeSocial extends Component {

    render() {
        return (
            <ScreenClassRender render={screenClass => (
                <img src={maoamiga} width={["xs", "sm", "md"].includes(screenClass) ? "30px" : "60px"} style={{ padding: 0 }} />
            )} />
        );
    }
}

class Whats extends Component {

    render() {
        return (
            <img src={whats} width="45px" style={{ padding: 0 }} />
        );
    }
}

class Email extends Component {

    render() {
        return (
            <img src={email} width={this.props.width} style={{ padding: 0 }} />
        );
    }
}

class Instagram extends Component {

    render() {
        return (
            <img src={insta} width={this.props.width} style={{ padding: 0 }} />
        );
    }
}

class Linkedin extends Component {

    render() {
        return (
            <img src={linke} width={this.props.width} style={{ padding: 0 }} />
        );
    }
}

class Spotify extends Component {

    render() {
        return (
            <img src={spotify} width={this.props.width} style={{ padding: 0 }} />
        );
    }
}

class LeftArrow extends Component {

    render() {
        return (
            <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.453125 7.20312C0.03125 7.67188 0.03125 8.375 0.453125 8.79688L6.82812 15.1719C7.29688 15.6406 8 15.6406 8.42188 15.1719L9.5 14.1406C9.92188 13.6719 9.92188 12.9688 9.5 12.5469L4.95312 8L9.5 3.5C9.92188 3.07812 9.92188 2.32812 9.5 1.90625L8.42188 0.828125C8 0.40625 7.29688 0.40625 6.82812 0.828125L0.453125 7.20312Z" fill="#6BBF71" />
            </svg>
        );
    }
}

class RightArrow extends Component {

    render() {
        return (
            <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.54688 8.79688C9.96875 8.32812 9.96875 7.625 9.54688 7.20312L3.17188 0.828125C2.70313 0.359375 2 0.359375 1.57813 0.828125L0.5 1.85938C0.078125 2.32812 0.078125 3.03125 0.5 3.45312L5.04688 8L0.5 12.5C0.078125 12.9219 0.078125 13.6719 0.5 14.0938L1.57812 15.1719C2 15.5938 2.70312 15.5938 3.17188 15.1719L9.54688 8.79688Z" fill="#6BBF71" />
            </svg>

        );
    }
}

class DownArrow extends Component {

    render() {
        return (
            <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.3047 14.8203C11.0078 15.4531 12.0625 15.4531 12.6953 14.8203L22.2578 5.25781C22.9609 4.55469 22.9609 3.5 22.2578 2.86719L20.7109 1.25C20.0078 0.617188 18.9531 0.617188 18.3203 1.25L11.5 8.07031L4.75 1.25C4.11719 0.617188 2.99219 0.617188 2.35938 1.25L0.742188 2.86719C0.109375 3.5 0.109375 4.55469 0.742188 5.25781L10.3047 14.8203Z" fill="#F2D4AE" />
            </svg>
        );
    }
}

export {
    PrevArrow,
    NextArrow, Social, Comunidade, Criatividade, Fome, Pobreza, Saude,
    Criancas, Pandemia, SaudeMental, Renda, NutricaoInfantil, VulnerabilidadeSocial, Whats,
    Email, Instagram, Linkedin, Spotify,
    LeftArrow, RightArrow,
    DownArrow,
    MaoComDinheiro, GrupoDePessoas,
    Menu,
    Jantar
};