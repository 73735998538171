import React, { Component } from 'react';
import { ScreenClassRender, Visible } from 'react-grid-system';
import { RightArrow, LeftArrow } from "../../components/Icons";
import './index.css';

class SlideHorizontal extends Component {

  constructor(props) {
    super(props)

    let listRefs = [];

    for (let index = 0; index < this.props.itens.length; index++) {
      listRefs.push(React.createRef())
    }

    this.state = {
      listRefs: listRefs,
      atual: 0
    };

    this.scrollProximo = this.scrollProximo.bind(this);
    this.scrollAnterior = this.scrollAnterior.bind(this);
  }

  scrollProximo() {
    if (this.state.atual < this.state.listRefs.length - 1) {

      let novoIndex = this.state.atual + 1;

      this.setState({
        atual: novoIndex
      })

      this.state.listRefs[novoIndex].current.scrollIntoView({ behavior: 'smooth', inline: "center", block: "end" });
    }
  }

  scrollAnterior() {
    if (this.state.atual > 0) {

      let novoIndex = this.state.atual - 1;

      this.setState({
        atual: novoIndex
      })

      this.state.listRefs[novoIndex].current.scrollIntoView({ behavior: 'smooth', inline: "center", block: "end" });
    }
  }

  render() {
    return (
      <ScreenClassRender render={screenClass => (
        <>
          <div style={{
            margin: 0,
            padding: ['xs', 'sm', "md"].includes(screenClass) ? "5vh 5vw 2vh 5vw" : "5vh 0px 2vh 15vw",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
            <span style={{
              fontSize: "max(1.7vw, 13px)",
              fontWeight: 800,
              color: 'var(--black)'
            }}>{this.props.title}</span>
            <Visible xs sm>
              <div>
                <span style={{cursor:"pointer" }} onClick={this.scrollAnterior}>
                  <LeftArrow />
                </span>
                <span style={{ marginRight: "30px" }}></span>
                <span style={{cursor:"pointer"}} onClick={this.scrollProximo}>
                  <RightArrow />
                </span>
              </div>
            </Visible>
          </div >

          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            overflowX: "auto",
            overflowY: "hidden",
            padding: ['xs', 'sm', "md"].includes(screenClass) ? "0px 5vw 10vh 5vw" : "0px 15vw 10vh 15vw"
          }}>
            {
              this.props.itens.map(function (item, i) {
                return <div style={{
                  marginRight: ['xs', 'sm', "md"].includes(screenClass) ? "5vw" : "",
                  width: "max(220px, 20vw)",
                  minWidth: "max(220px, 20vw)"
                }}
                  ref={this.state.listRefs[i]}
                >
                  {item}
                </div>
              }, this)
            }
          </div>
        </>
      )} />
    );
  }
}
export default (SlideHorizontal);