import React, { Component } from 'react';
import { Row, Col } from 'react-grid-system';
import "./index.css";

class Passo extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="backgroundPasssoComoFunciona">
                <Row justify="center" style={{ margin: 0 }}>
                    <Col xs="content" style={{ padding: 0 }}>
                        <div className="dotComoFunciona">
                            <span>{this.props.numeroPassso}</span>
                        </div>
                    </Col>

                    <Col xs={12} style={{ padding: 0 }}>
                        <div className="tituloComoFunciona">
                            <span>{this.props.titulo}</span>
                        </div>
                    </Col>

                    <Col xs={10} style={{ padding: 0 }}>
                        <div className="textoComoFunciona">
                            <span> {this.props.texto} </span>
                        </div>
                    </Col>
                </Row >
            </div>

        );
    }
}

export default (Passo);