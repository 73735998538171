import React, { Component } from 'react';
import { Row, Col, ScreenClassRender, Visible } from 'react-grid-system';
import ProgressBar from '../../../components/ProgressBar';
import ActionButton from '../../../components/ActionButton';
import { firebaseDatabase } from '../../../utils';
import "./index.css";

const COLLECTION_CONFIGS = "config";

class MetaPalas extends Component {

    constructor() {
        super()

        this.state = { metaPalasReais: 0 };
    }

    componentDidMount() {
        this.buscaConfigs();
    }

    async buscaConfigs() {
        const response = firebaseDatabase.collection(COLLECTION_CONFIGS);
        const data = await response.get();
        let configs = data.docs[0].data();

        this.setState({
            metaPalasCestas: configs.metaPalasCestas,
            metaPalasReais: configs.metaPalasReais,
            palasTotalArrecadadoReais: configs.palasTotalArrecadadoReais
        })
    }

    render() {
        return (
            <ScreenClassRender render={screenClass => (
                <Row style={{ margin: 0 }}>
                    <Col className="backgroundMetaPalas" xs={12}>
                        <Row justify="center">
                            {/* Arrecadado */}
                            <Col xs={12} sm={12} md={12} lg={8} xl={8} style={{ padding: 0, textAlign: "left", textAlign: "center", marginTop: "50px" }}>
                                <Row justify="center" style={{ margin: 0 }}>
                                    <Col xs="content">

                                    </Col>
                                </Row>
                                <Row justify="center" style={{ margin: "10px 0px 0px 0px" }}>
                                    <Col xs="content">
                                        <span className="metaArrecadacaoTexto">META DE ARRECADAÇÃO</span>
                                    </Col>
                                </Row>
                                <Row justify="center" style={{ margin: "10px 0px 0px 0px" }}>
                                    <Col xs="content">
                                        {this.state.metaPalasReais && <span className="textoValorArrecadacao">
                                            <span style={{ fontSize: "max(1.3vw, 12px)" }}>R$</span>
                                            {this.state.metaPalasReais.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }).replace("R$", "")}
                                            <Visible xs sm md>
                                                <br/>
                                            </Visible>
                                            <span style={{ color: "var(--red)", fontSize: "max(1.3vw, 12px)", fontWeight: "900", margin:"0vw 2vw 0vh 2vw" }}>=</span>
                                            <Visible xs sm md>
                                                <br/>
                                            </Visible>
                                            <span>{this.state.metaPalasCestas} <span  style={{ fontSize: "max(1.3vw, 12px)"}}>Cestas básicas </span></span>
                                        </span>}
                                    </Col>
                                </Row>
                                <Row justify="center" style={{ margin: "10px 0px 0px 0px" }}>
                                    <Col xs={12}>
                                        <span className="metaArrecadacaoTexto">JÁ ARRECADADO</span>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6} style={{marginTop:"20px"}}>
                                        <ProgressBar atual={this.state.palasTotalArrecadadoReais} total={this.state.metaPalasReais}/>
                                    </Col>
                                </Row>
                            </Col>

                            {/* Quero apoiar */}
                            <Col xs={12} sm={12} md={12} lg={3} xl={3} style={{ padding: 0, marginTop: ['xs', 'sm', 'md'].includes(screenClass) ? "50px" : "110px" }}>
                                <Row justify="center" style={{ margin: 0 }}>
                                    <Col xs="content">
                                        <div onClick={() => { window.location.href = "/doar/palas" }}><ActionButton arrecadado>QUERO APOIAR</ActionButton></div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row >
            )} />
        );
    }
}

export default (MetaPalas);