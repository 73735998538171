import React, { Component } from 'react';
import "./index.css";
import { Row, Col, ScreenClassRender } from 'react-grid-system';

class Causa extends Component {

    render() {
        return (
            <ScreenClassRender render={screenClass => (
                <div>
                    <div className="dot" style={{
                        width: ["xs", "sm", "md"].includes(screenClass) ? "50px" : "100px",
                        height: ["xs", "sm", "md"].includes(screenClass) ? "50px" : "100px",
                    }}>
                        <div className="content-causa" style={{marginTop: ["xs", "sm", "md"].includes(screenClass) ? "10px" : "20px"}}>
                            <Row style={{ margin: 0 }}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 0 }}>
                                    {this.props.icon}
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                        <span style={{ fontSize: "max(10px, 0.95vw)", fontWeight: "600" }}>
                            {this.props.children}
                        </span>
                    </div>
                </div>
            )} />
        );
    }
}

export default (Causa);