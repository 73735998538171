import React, { Component } from 'react';
import { Row, Col, Hidden, Visible, ScreenClassRender } from 'react-grid-system';
import Conceito from './Conceito';
import "./index.css";
import globo from '../../../assets/globo.png'

class SobreNos extends Component {

    constructor() {
        super()
    }

    render() {
        return (
            <ScreenClassRender render={screenClass => (
                <Row style={{ margin: 0 }}>
                    <Col xs={12} sm={12} md={12} lg={8} xl={8} style={{ padding: 0 }}>
                        <Row justify="center" style={{ margin: 0, marginTop: "50px" }}>
                            <Col xs={10} style={{ padding: 0, textAlign: "" }}>
                                <span style={{ fontSize: "max(2.1vw, 14px)", fontWeight: 900, color: 'var(--black)' }}>SOBRE NÓS</span>
                            </Col>
                            <Col xs={10} style={{ padding: 0, textAlign: "", marginTop: "20px" }}>
                                <span style={{ fontSize: "max(1.2vw, 14px)", fontWeight: 600, color: 'var(--black)' }}>Somos um projeto social criativo em meio a pandemia</span>
                            </Col>
                            <Col xs={10} style={{ padding: 0, textAlign: "", marginTop: "20px" }}>
                                <span style={{ fontSize: "max(1.2vw, 14px)", fontWeight: 800, color: 'var(--black)' }}>#VIRALIZEOAMOR</span>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={8} xl={8} style={{ padding: 0 }}>
                        <Row justify={["xs", "sm", "md"].includes(screenClass) ? "end" : "center"} style={{ margin: 0, marginTop: "5vh" }}>
                            <Col xs={11} sm={11} md={11} lg={10} xl={10} style={{ padding: 0 }}>
                                <Row style={{ margin: 0 }}>
                                    <Col xs={6} sm={6} md={6} lg={12} xl={12} style={{ padding: 0 }}>
                                        <hr style={{ borderTop: "1px solid #9E9E9E" }}></hr>
                                        <Conceito
                                            icon="social"
                                            title="IMPACTO SOCIAL"
                                        >
                                            Buscamos ser lembrados como uma geração que priorizou reduzir as desigualdades causadas e amplificadas pela pandemia do COVID-19.
                                            <br /><br />
                                            - Canal seguro e confiável para arrecadação financeira<br />
                                            - Conscientização da comunidade<br />
                                            - Fonte confiável de informações relacionadas a pandemia<br />
                                        </Conceito>
                                    </Col>
                                    <Visible xs sm md>
                                        <Col xs={6} sm={6} md={6} style={{ padding: 0 }}>
                                            <img className="centerVertical" src={globo} width="100%" />
                                        </Col>
                                    </Visible>

                                    <Col xs={11} sm={6} md={6} lg={12} xl={12} style={{ padding: 0 }}>
                                        <Conceito
                                            icon="comunidade"
                                            title="COMUNIDADE"
                                        >
                                            Queremos gerar conexões improváveis para maximizar o impacto social na pandemia de forma exponencial.
                                            <br /><br />
                                            - Hub para trabalho em rede com propósito<br />
                                        </Conceito>
                                    </Col>
                                    <Col xs={11} sm={11} md={11} lg={12} xl={12} style={{ padding: 0 }}>
                                        <Conceito
                                            title="CRIATIVIDADE"
                                        >
                                            Exploramos novas formas de gerar impacto social na pandemia através de tecnologia e inovação.
                                            <br /><br />
                                            - Novas formas de engajamento exponencial online<br />
                                            - Tecnologias inovadoras aplicadas para viralizar o amor<br />
                                            - Organização descentralizada e autonoma para escalar a transformação cultural da comunidade<br />
                                        </Conceito>
                                        <hr style={{ borderTop: "1px solid #9E9E9E", margin: "50px 0px 0px 0px" }}></hr>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Hidden xs sm md>
                        <Col lg={4} xl={4} style={{ padding: 0 }}>
                            <img className="centerVertical" src={globo} width="100%" />
                        </Col>
                    </Hidden>

                </Row >
            )} />
        );
    }
}

export default (SobreNos);